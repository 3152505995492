import React from 'react';
import './FaqSection.scss';
import { Container, Row, Col, Accordion } from 'react-bootstrap';

const FaqSection = ({
    id,
    title,
    subtitle,
    className,
    faqs
}) => {
    faqs = faqs ?? [];

    return (
        <div id={id} className={`faq-section section-padding ${className ?? ''}`}>
            <Container>
                <Row>
                    <Col md="12">
                        {title && <span className="title-meta">{title}</span>}
                        {subtitle && <h2 className="title">{subtitle}</h2>}
                    </Col>
                </Row>
            </Container>
            <Container>
                <Accordion defaultActiveKey="0">
                    {faqs.map((q, index) => (
                        <Accordion.Item key={index} eventKey={index}>
                            <Accordion.Header>{q.title}</Accordion.Header>
                            <Accordion.Body>{q.response}</Accordion.Body>
                        </Accordion.Item>
                    ))}
                </Accordion>
            </Container>
        </div >
    );
};

export default FaqSection;