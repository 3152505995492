import React, { useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import './Header.scss';
import { Button, Stack } from 'react-bootstrap';

const Header = () => {
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const toggleMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    };

    return (
        <>
            <Navbar
                expand="md"
                variant='dark'
                className='header'
                onToggle={toggleMobileMenu}
            >
                <Container>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Brand className='header--brand'
                        href="#home">
                        <div className="logo">
                            <img src='/icons/logo.png' />
                        </div>
                    </Navbar.Brand>
                    <Nav className="header--nav">
                        <Nav.Link href="#our-story">Nuestra Historia</Nav.Link>
                        <Nav.Link href="#when-where">Cuando y Donde</Nav.Link>
                        <Nav.Link href="#gallery">Momentos Top</Nav.Link>
                        <Nav.Link href="#gettingthere">Como llegar</Nav.Link>
                        <Nav.Link href="#hotels">Hoteles</Nav.Link>
                    </Nav>
                    <Nav className="header--nav">
                        <Button className='rspv' variant='light' href='#rspv'>RSPV</Button>
                    </Nav>
                </Container>
            </Navbar>
            <Offcanvas className="offcanvas-menu" show={showMobileMenu} onHide={toggleMobileMenu} onToggle={toggleMobileMenu}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title></Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body className="offcanvas-menu-body">
                    <Stack className='stack'>
                        <div className="logo-section">
                            <div className="logo">Esme <small>&</small> Carlos</div>
                            <div className="date">22/03/2025</div>
                        </div>
                        <div className='offcanvas-menu-container'>
                            <ul>
                                <li><a onClick={toggleMobileMenu} href='#'>Inicio</a></li>
                                <li><a onClick={toggleMobileMenu} href='#our-story'>Nuestra Historia</a></li>
                                <li><a onClick={toggleMobileMenu} href='#when-where'>Cuando y Donde</a></li>
                                <li><a onClick={toggleMobileMenu} href="#gallery">Momentos Top</a></li>
                                <li><a onClick={toggleMobileMenu} href="#gettingthere">Como llegar</a></li>
                                <li><a onClick={toggleMobileMenu} href="#hotels">Hoteles</a></li>
                                <li><a onClick={toggleMobileMenu} href="#rspv">RSPV</a></li>
                            </ul>
                        </div>
                        <div className='offcanvas-menu-footer'>
                            <span className="separator"></span>
                            <p>
                                22 de marzo de 2025, Ajijic<br />
                                Te Esperamos
                            </p>
                        </div>
                    </Stack>
                </Offcanvas.Body>
            </Offcanvas>
        </>

    );
};

export default Header;