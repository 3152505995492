import React, { useEffect, useState } from 'react';
import './CountdownSection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const CountdownSection = ({
    id
}) => {

    const WEDDING_DATE = new Date(2025, 2, 22, 17, 0, 0, 0);
    const calculateTimeLeft = () => {
        const now = new Date();
        const difference = WEDDING_DATE - now;
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const interval = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(interval);
    }, []);


    return (
        <div id={id} className="countdown-section section-padding bg-img bg-fixed">
            <Container>
                <Row>
                    <Col md="12" className='section-head'>
                        <h4>
                            We will become a family in
                        </h4>
                    </Col>
                </Row>
                <Row>
                    <Col md="12" className=''>
                        <ul>
                            <li><span>{timeLeft.days || '0'}</span> days</li>
                            <li><span>{timeLeft.hours || '0'}</span> hours</li>
                            <li><span>{timeLeft.minutes || '0'}</span> minutes</li>
                            <li><span>{timeLeft.seconds || '0'}</span> seconds</li>
                        </ul>
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default CountdownSection;