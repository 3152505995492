import React, { useState, useEffect } from 'react';
import './GettingThereSection.scss';
import { Card, Carousel, Container, Row, Col } from 'react-bootstrap';

const GettingThereSection = ({
    id,
    title,
    subtitle,
    cards,
}) => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
    cards = cards ?? [];

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth <= 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const renderedCards = cards.map((card, index) => (
        <div key={index} className='custom-card-wrapper'>
            <Card>
                <Card.Img variant="top" src={card.image ?? '/images/hotel.jpeg'} />
                <Card.Body>
                    <Card.Title><h5>{card.title}</h5></Card.Title>
                    <Card.Text as="div">
                        {card.body}
                    </Card.Text>
                </Card.Body>
            </Card>
        </div>
    ));

    const cardsContainer = isMobile ? (<Carousel indicators={true}
        variant='dark'
        controls={false}
        interval={null}
        touch={true}>
        {renderedCards.map((item, index) => (
            <Carousel.Item key={index}>
                {item}
            </Carousel.Item>
        ))}
    </Carousel>) : (
        <Row>
            {renderedCards.map((item, index) => (
                <Col md="4" key={index}>
                    {item}
                </Col>
            ))}
        </Row>
    );

    return (
        <div id={id} className="getting-there-section section-padding clear section-padding bg-pink">
            <Container>
                <Row>
                    <Col md="12">
                        {title && <span className="title-meta">{title}</span>}
                        {subtitle && <h2 className="title">{subtitle}</h2>}
                    </Col>
                </Row>
            </Container>
            <Container className='mt-3'>
                {cardsContainer}
            </Container>
        </div >
    );
};

export default GettingThereSection;