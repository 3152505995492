import React from 'react';
import './StorySection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const StorySection = ({
    id
}) => {
    return (
        <div id={id} className="story-section section-padding">
            <Container>
                <Row>
                    <Col md="5" className='mb-30'>
                        <div className='story-img'>
                            <div className='img'>
                                <img className='img-fluid' src='images/story/story-cover.webp' alt='' />
                            </div>
                        </div>
                    </Col>
                    <Col md="7">
                        <h3 className='story-title'>Nuestra historia</h3>

                        <p>
                            Hola a todos, somos Esme y Carrillo. Queremos compartir con ustedes cómo comenzó nuestra historia. En 2022, estábamos en Vancouver, Canadá, durante la temporada de ski, por casualidad, terminamos en el mismo bar gracias a unos amigos en común. Empezamos a hablar y, sorpresa, ¡nos dimos cuenta de que compartimos una gran pasión por el ski! Decidimos ir a esquiar juntos y desde ahí, no hemos parado.
                        </p>
                        <p>
                            <b>¡Diversión a Tope!</b> Con el tiempo, descubrimos que no solo compartimos el amor por el ski, sino también por viajar, comer y lanzarnos a nuevas aventuras. Cada viaje y cada experiencia han sido momentos llenos de risas y recuerdos inolvidables.
                        </p>
                        <p>
                            Y aquí viene lo mejor, en el verano de 2023, regresamos a la misma montaña donde esquiamos juntos por primera vez. Ahí, rodeados de naturaleza y recuerdos, nos comprometimos para ser compañeros de aventuras por siempre. Desde ese día, nuestra vida ha estado llena de alegrías, y estamos agradecidos por todo el amor y apoyo que hemos recibido de todos ustedes.
                        </p>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default StorySection;