import React from 'react';
import './SeeYouSection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const SeeYouSection = ({
    id
}) => {

    return (
        <div id={id} className="seeyou-section section-padding bg-img bg-fixed">
            <Container>
                <Row>
                    <Col md="12" className='section-head text-center'>
                        <span><i className="ti-heart"></i></span>
                        <h4>Looking forward to see you!</h4>
                        {/* <h3>22.03.2025</h3> */}
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default SeeYouSection;