import React from 'react';
import './AnnounceSection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const AnnounceSection = ({
    id,
    logo,
    title,
    text,
    body,
    className
}) => {

    return (
        <div id={id} className={`announce-section section-padding ${className ?? ''}`}>
            <Container>
                <Row>
                    <Col md="12" className='text-center'>
                        {logo && <div className='logo'>
                            <img src={logo} />
                        </div>}
                        {title && <h3 className="title">{title}</h3>}
                        {text && <p className="subtitle">{text}</p>}
                        {body}
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

export default AnnounceSection;