import React from 'react';
import './IntroSection.scss';

const IntroSection = () => {
    return (
        <div className="intro-container">
            <div className="intro-content">
                <div className="mobile-logo">
                    <img src='/icons/logo.png' />
                </div>
                <h1 className="names">Esmeralda <span>&</span> Carlos</h1>
                <div className="text">
                    <p className="save-the-date cormorant-garamond-regular">SAVE THE DATE</p>
                    <p className="date  cormorant-garamond-regular">22 MARZO 2025</p>
                </div>
            </div>
            {/* <div className="mobile-logo">
                <img src='/icons/logo.png' />
            </div> */}
        </div>
    );
};

export default IntroSection;