import React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import './styles/themify-icons.css';
import './App.scss';
import Header from './components/Header';
import IntroSection from './components/IntroSection';
import StorySection from './components/StorySection';
import BoxSection from './components/BoxSection';
import CountdownSection from './components/CountdownSection';
import GettingMarriedSection from './components/GettingMarriedSection';
import SeeYouSection from './components/SeeYouSection';
import GallerySection from './components/GallerySection';
import GettingThereSection from './components/GettingThereSection';
import RspvSection from './components/RspvSection';
import AnnounceSection from './components/AnnounceSection';
import FaqSection from './components/FaqSection';


function App() {
  return (
    <div className="App">
      <Header />
      <IntroSection />
      <GettingMarriedSection id="getting-married" />
      <CountdownSection id="countdown" />
      <StorySection id="our-story" />
      <SeeYouSection id="seeyou" />
      <BoxSection id="when-where" />
      <GallerySection id="gallery" />
      <GettingThereSection id="gettingthere"
        title='Getting there'
        subtitle=''
        cards={[
          {
            title: 'Aeropuerto',
            image: 'images/avion.webp',
            body: <>
              <p>Aeropuerto Internacional de Guadalajara</p>
              <p>El aeropuerto de Guadalajara se encuentra a 40 kilómetros (40 min)  del venue donde estaremos celebrando la boda. Recomendamos a todos nuestros invitados que estarán volando a méxico llegar a este aeropuerto.</p>
              <p><i className='ti-location-pin'></i> Carr. Guadalajara - Chapala Km 17.5, 45659 Jal., México</p>
            </>
          },
          {
            title: 'Transporte',
            image: 'images/transporte.webp',
            body: <>
              <p>Beboy transportes</p>
              <p>Magaly Ruiz - +52 1 33 3105 1698 (whatsApp)</p>
              <p>Transporte privado, hay que comunicarse con ellos via whatsapp, indicando la hora de llegada, y lugar donde los estarán recogiendo.</p>
            </>
          },
          {
            title: 'Taxi',
            image: 'images/taxi.webp',
            body: <>
              <p>Taxi o Servicio de Transporte Privado</p>
              <p>Uber no puede operar en el aeropuerto, por lo que no es recomendable esta opción.
                Puedes utilizar el servicio de transporte de Taxi privado del aeropuerto que es seguro y confiable.</p>
            </>
          },
          {
            title: 'Wedding Venue',
            image: 'images/ajijic.webp',
            body: <>
              <p>Nimue Marina Residence & Hotel Boutique</p>
              <p><i className='ti-location-pin'></i> P.º de La Huerta No 57, Col. La Floresta, 45920 Ajijic, Jal., México</p>
            </>
          },
          {
            title: 'Ceremonia',
            image: 'images/templo.webp',
            body: <>
              <p>Templo San Jose de la floresta</p>
              <p><i className='ti-location-pin'></i> P.º del Mirador 36, Fracc. La Floresta, 45920 Ajijic, Jal., México</p>
            </>
          },
          {
            title: 'Welcome party',
            image: 'images/hotel-real-chapala.webp',
            body: <>
              <p>Hotel real de chapala </p>
              <p><i className='ti-location-pin'></i> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
              </p>
            </>
          }
        ]} />
      <GettingThereSection id="hotels"
        title={null}
        subtitle='Hoteles'
        cards={[
          {
            title: 'Hotel Real de Chapala',
            image: 'images/hotel-real-chapala.webp',
            body: <>
              <p>Hotel real de chapala </p>
              <p>
                Este hotel se encuentra a 2 min caminando de Nimue, donde será la recepción.
                Es nuestra recomendación favorita para estar cerca y puedan disfrutar al máximo.
                Les compartimos <a href='/hotel-real-chapala.pdf' target='_blank'>este PDF</a> para que puedan utilizar el codigo de reservacion de nuestra boda.
                Además, en este hotel celebraremos la fiesta de bienvenida y la tornaboda.
              </p>
              <p><a href='https://realdechapala.com/'>https://realdechapala.com</a></p>
              <p><i className='ti-location-pin'></i> P.º del Prado 20, Fraccionamiento La Floresta, 45920 Ajijic, Jal., México
              </p>
            </>
          },
          {
            title: 'Monte coaxala',
            image: 'images/montecoaxala.webp',
            body: <>
              <p><a href='https://www.montecoxala.com/'>https://www.montecoxala.com</a></p>
            </>
          },
          {
            title: 'Hotel danza del sol',
            image: 'images/danzadelsol.webp',
            body: <>
              <p><a href='http://hoteldanzadelsolcontacto.mx/'>http://hoteldanzadelsolcontacto.mx</a></p>
            </>
          }
        ]} />
      <AnnounceSection id="present"
        title={<span><i className='ti-gift' /> Honeymoon funds</span>}
        body={<>
          <p>
            ¡Estamos super felices de que puedas acompañarnos en nuestro día especial! Tu regalo lo disfrutaremos en nuestra honey moon, Nos encantaría que nos dejaras una nota diciendo qué tipo de locura te gustaría que hiciéramos. ¡Te prometemos mandar memorias y fotos!
          </p><p>
            ¡Gracias por ser parte de nuestras vidas y de esta increíble aventura!
          </p>
        </>} />
      <RspvSection id="rspv" />
      <FaqSection id="faq"
        logo={"/icons/logo.png"}
        title={"Preguntas"}
        subtitle={""}
        faqs={[{
          title: 'Que hacer en Ajijic, Jalisco?',
          response: <p>Nos encantaría que nos acompañen en nuestra boda en Ajijic,
            un pintoresco pueblo a orillas del Lago de Chapala, conocido por su encanto tradicional
            y su vibrante comunidad artística. Situado a solo 40 minutos (40 km) del Aeropuerto Internacional de Guadalajara.
            Disfruten de sus coloridas calles, deliciosa gastronomía y cálida hospitalidad. Para llegar,
            pueden tomar un vuelo a Guadalajara y luego un taxi o transporte privado a Ajijic.
            No olviden probar las especialidades locales y explorar el malecón junto al lago.
            ¡Estamos seguros de que se enamorarán de este lugar tanto como nosotros!<br />
            Aqui tienes unas ideas
            <ul>
              <li><a href='https://vm.tiktok.com/ZMrm1m11P/'>https://vm.tiktok.com/ZMrm1m11P/</a></li>
              <li><a href='https://vm.tiktok.com/ZMraoqDec/'>https://vm.tiktok.com/ZMraoqDec/</a></li>
            </ul>
          </p>
        }, {
          title: 'Que clima esperamos?',
          response: <p>
            En Ajijic, Jalisco, el clima en marzo es bastante agradable.
            Durante este mes, las temperaturas altas promedio rondan los 29°C (84°F),
            mientras que las temperaturas bajas promedio son de aproximadamente 10°C (50°F).
            El clima es generalmente seco, con una precipitación media de solo 5 mm (0.20 pulgadas),
            lo que hace que el aire sea menos húmedo en comparación con otros meses del año (Time and Date) (Weather Underground).
          </p>
        }, {
          title: 'Que debería usar? Código de Vestimenta.',
          response: <div>
            <p><i className='ti-music-alt' /> Welcome party - Casual, cocktail.</p>
            <p><i className='ti-instagram' /> Ceremonia - Formal, aqui tienes unas ideas del codigo de vestimenta <a href="https://vm.tiktok.com/ZMraVs2Jy/">https://vm.tiktok.com/ZMraVs2Jy</a> y <a href="https://vm.tiktok.com/ZMraqJaUc/">https://vm.tiktok.com/ZMraqJaUc</a></p>
          </div>
        }, {
          title: 'Nuestros restaurantes favoritos de la zona, un poco de gastronomía',
          response: <div>
            <h4>Restaurante "oia cocina de mar"</h4>
            <p><i className='ti-location-pin' />P.º Ramón Corona 32, Chapala Centro, 45900 Chapala, Jal., México</p>
            <h4>La VitaBella Soul Spa Hotel & Restaurant</h4>
            <p>
              <a href="http://www.lavitabellasoulspa.com/"></a>
            </p>
            <p>
              <i className='ti-location-pin' /> Rafael Osuna 250, Raquet Club, 45820 San Juan Cosalá, Jal., México
            </p>
          </div>
        }, {
          title: 'Como puedo llegar de la ceremonia al venue?',
          response: <p>
            La distancia entre la iglesia y el venue es de 1.3 km,
            podrán utilizar el transporte privado que estará por la zona, e incluso caminar,
            considerando que en el venue estará el valet parking para aquellos que lleven su propio auto.
          </p>
        }, {
          title: '¿Podemos llevar a nuestros niños a la fiesta?',
          response: <p>
            Para asegurar una experiencia memorable y relajada para todos nuestros invitados,
            hemos decidido que nuestra celebración será solo para adultos.
            Agradecemos su comprensión y esperamos compartir este día especial con ustedes.
          </p>
        }]} />
      < AnnounceSection id="footer"
        logo={"/icons/logo.png"}
        title={" "}
        className={'bg-pink'}
        text={"22 de Marzo de 2025 — Ajijic, Jalisco, México"} />

    </div >
  );
}

export default App;
