import React, { useEffect, useState, createRef } from 'react';
import './GallerySection.scss';
import { Container, Row, Col } from 'react-bootstrap';

const GallerySection = ({
    id
}) => {
    const images = [
        "/photos/image_45",
        // "/photos/image_01",
        // "/photos/image_02",
        // "/photos/image_03",
        "/photos/image_04",
        "/photos/image_05",
        // "/photos/image_06",
        "/photos/image_07",
        "/photos/image_08",
        "/photos/image_09",
        "/photos/image_10",
        // "/photos/image_11",
        "/photos/image_12",
        // "/photos/image_13",
        "/photos/image_14",
        "/photos/image_15",
        "/photos/image_16",
        "/photos/image_17",
        // "/photos/image_18",
        "/photos/image_19",
        "/photos/image_20",
        "/photos/image_21",
        // "/photos/image_22",
        "/photos/image_23",
        // "/photos/image_24",
        "/photos/image_25",
        "/photos/image_26",
        // "/photos/image_27",
        // "/photos/image_28",
        "/photos/image_29",
        "/photos/image_30",
        "/photos/image_31",
        "/photos/image_32",
        "/photos/image_33",
        "/photos/image_34",
        // "/photos/image_35",
        "/photos/image_36",
        "/photos/image_37",
        "/photos/image_38",
        "/photos/image_39",
        // "/photos/image_40",
        "/photos/image_41",
        "/photos/image_42",
        "/photos/image_43",
        "/photos/image_44",
        "/photos/image_46",
        "/photos/image_47",
        "/photos/image_48",
        "/photos/image_49",
        "/photos/image_50",
        // "/photos/image_51",
        "/photos/image_52",
        // "/photos/image_53",
        // "/photos/image_54",
        // "/photos/image_55",
        "/photos/image_56",
        // "/photos/image_57",
        // "/photos/image_58",
        // "/photos/image_59",
        // "/photos/image_60",
        // "/photos/image_61",
        // "/photos/image_62",
        // "/photos/image_63",
        // "/photos/image_64",
        // "/photos/image_65",
        "/photos/image_66"
    ]

    const [itemsRef, setItemsRef] = useState([]);
    useEffect(() => {
        setItemsRef((itemsRef) => Array(images.length)
            .fill()
            .map((_, i) => itemsRef[i] || createRef())
        )
    }, []);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [offset, setOffset] = useState(0);
    const showPrevious = currentIndex !== 0;
    const showNext = images.length - 1 !== currentIndex;
    const [touchStartX, setTouchStartX] = useState(null);
    const [touchEndX, setTouchEndX] = useState(null);
    const handleTouchStart = (e) => {
        setTouchStartX(e.touches[0].clientX);
    };

    const handleTouchMove = (e) => {
        setTouchEndX(e.touches[0].clientX);
    };

    const handleTouchEnd = () => {
        if (touchStartX !== null && touchEndX !== null) {
            const difference = touchStartX - touchEndX;
            if (Math.abs(difference) > 50) {
                if (difference > 0) {
                    handleNext();
                } else {
                    handlePrev();
                }
            }
        }
        setTouchStartX(null);
        setTouchEndX(null);
    };

    const calculateOffset = (index) => {
        var result = 0;
        for (var i = 0; i < itemsRef.length && i < index; i++) {
            result = result + itemsRef[i]?.current?.scrollWidth ?? 0;
        }
        return result;
    }

    const handlePrev = () => {
        const newIndex = Math.max(0, currentIndex - 1);
        const offset = calculateOffset(newIndex);
        setCurrentIndex(newIndex);
        setOffset(offset);
    };

    const handleNext = () => {
        const newIndex = Math.min(images.length - 1, currentIndex + 1);
        const offset = calculateOffset(newIndex);
        setCurrentIndex(newIndex);
        setOffset(offset);

    };

    return (
        <div id={id}
            className="gallery-section section-padding"
            onTouchStart={handleTouchStart}
            onTouchMove={handleTouchMove}
            onTouchEnd={handleTouchEnd}>
            <Container>
                <Row>
                    <Col md="12">
                        <span className="title-meta">Gallery</span>
                        <h2 className="title">Momentos Top</h2>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className='custom-carousel-wrapper'>
                    <div className='custom-carousel-inner' style={{ transform: `translateX(-${offset}px)` }}>
                        {images.map((img, index) => (
                            <div className='custom-carousel-item' key={index} ref={itemsRef[index]}>
                                <picture>
                                    <source srcSet={`${img}-800.webp`} type="image/webp" />
                                    <source srcSet={`${img}-800.jpg`} type="image/jpeg" />
                                    <img className='' src={`${img}.jpeg`} alt={`Slide ${index}`} loading="lazy" />
                                </picture>
                            </div>
                        ))}
                    </div>
                    <div className='custom-carousel-controls'>
                        <div className='custom-carousel-control-container'>
                            {showPrevious &&
                                <button className='custom-carousel-control-prev' onClick={handlePrev}>
                                    <i className='ti-angle-left' />
                                </button>
                            }
                        </div>
                        <div className='custom-carousel-control-container'>
                            {showNext &&
                                <button className='custom-carousel-control-next' onClick={handleNext}>
                                    <i className='ti-angle-right' />
                                </button>}
                        </div>
                    </div>
                </div>

            </Container >
        </div >
    );
};

export default GallerySection;